import React from "react"
import Layout from "../components/layout"
import TermsAndConditionspage from "../components/TermsAndConditions"

const TermsAndConditions = () => (
  <Layout>
    <TermsAndConditionspage/>
  </Layout>
)

export default TermsAndConditions
